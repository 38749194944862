
import { copySelectElementContents, copySelectTextContents, MoDate } from '@mobro/libs';
import { Iobject } from '@mobro/libs/es/type';
import { IRequestMethod, request } from '@mobro/request';
import { Message } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class ActivityList extends Vue {
    private activityList = [];
    private showPreview = false;
    private newsItemList = [];
    private dateStr = '';
    private posterUrl = '';
    private posterWhiteUrl = '';
    private sentenceTxt = '';
    private picture = '';
    private morning = '';
    private pagination: Iobject = {
        current: 1,
        pageSize: 7,
        total: 0,
    };
    private dialogFormVisible = false;
    private dialogFormEdit = false; // 修改活动配置
    private activityForm: Iobject = { conf: '' };

    created() {
        this.getActivityList();
    }

    async onCreateActivity() {
        const activityConf = JSON.parse(this.activityForm.conf);
        if (this.dialogFormEdit) {
            await request({
                url: '/api/activity/update',
                method: IRequestMethod.POST,
                params: activityConf,
            });
        } else {
            if (activityConf.activityId) {
                Message.error('配置不正确');
                return;
            }
            await request({
                url: '/api/activity/create',
                method: IRequestMethod.POST,
                params: activityConf,
            });
        }
        this.getActivityList();
        this.dialogFormVisible = false;
    }

    getActivityList() {
        request({
            url: '/api/activity/list',
            params: {
                activityType: 2,
            },
        }).then(res => {
            this.pagination = {
                current: res.current || 1,
                pageSize: res.pageSize || 100,
                total: res.total,
            };
            this.activityList = (res.data || []).map((x: Iobject) => {
                return {
                    ...x,
                };
            });
        });
    }

    tableRowClassName(x: Iobject) {
        const { rowIndex } = x;
        if (rowIndex === 1) {
            return 'warning-row';
        } else if (rowIndex === 3) {
            return 'success-row';
        }
        return '';
    }

    async onActivityIdClick(row: Iobject) {
        await copySelectTextContents(row.activityId);
        Message.success('复制成功！');
    }

    onPageCurrentChange(x: number) {
        this.pagination.current = x;
        this.getActivityList();
    }

    formatTime(row: Iobject) {
        return `${MoDate.format('YYYY/MM/DD hh:mm:ss', row.startTime)}
         ~
        ${MoDate.format('YYYY/MM/DD hh:mm:ss', row.endTime)}`;
    }

    onEditActivity(row: Iobject) {
        this.activityForm.conf = JSON.stringify(row, undefined, 4);
        this.dialogFormEdit = !!row.activityId;
        this.dialogFormVisible = true;
    }

    onDelActivity(row: Iobject) {
        request({
            url: '/api/activity/del',
            method: IRequestMethod.GET,
            params: {
                ids: row.activityId,
            },
        }).then((res: Iobject) => {
            console.log(res);
            this.getActivityList();
        });
    }

    onCopyActivityConf(row: Iobject) {
        const pre = document.createElement('pre');
        pre.innerHTML = JSON.stringify(row, undefined, 4);
        pre.setAttribute('style', 'height: 1px; width: 1px; overflow: hidden; position: fixed; left: -999px; top: 0;');
        document.body.append(pre);
        copySelectElementContents(pre).then(() => {
            Message.success('复制成功');
            document.body.removeChild(pre);
        });
    }
}
